import { useState } from 'react';
import { ethers } from 'ethers';
import './App.css';
import shuttleABI from "./contracts/shuttleABI.json";
import loveABI from "./contracts/loveABI.json";
const SHUTTLE_CONTRACT_ADDRESS = "0xBdd4d82810A74a68D9eAAF24c8Ba4d2d78947789";
const LOVE_CONTRACT_ADDRESS = "0x593BAA143E70517c1E07C5FC311E91d52aF34Cdd";

// Reload page if network is changed
if (window.ethereum) {
  window.ethereum.on('chainChanged', function (networkId) {
      window.location.reload();
  });
}

function App() {
  const [isConnected, setConnected] = useState(false);
  const [isGobi, setIsGobi] = useState(true);
  const [connectBtnString, setConnectBtnString] = useState("Connect Wallet")
  const [approveBtnString, setApproveBtnString] = useState("Approve LOVE")
  const [xferBtnString, setStakeBtnString] = useState("Transfer LOVE")
  const [account, setAccount] = useState();
  const [balance, setBalance] = useState();
  const [loveBalance, setLoveBalance] = useState();
  const [approvedLOVE, setApprovedLOVE] = useState();
  const [sentLOVE, setSentLOVE] = useState();

  async function updateBalances() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const signerBalance = await signer.getBalance();
    const signerAddress = await signer.getAddress();
    const loveContract = new ethers.Contract(LOVE_CONTRACT_ADDRESS, loveABI, signer);
    const signerLoveBalance = await loveContract.balanceOf(signerAddress);
    const signerApprovedLOVE = await loveContract.allowance(signerAddress, SHUTTLE_CONTRACT_ADDRESS);
    const shuttleContract = new ethers.Contract(SHUTTLE_CONTRACT_ADDRESS, shuttleABI, signer);
    const signerSentLOVE = await shuttleContract.loveSent(signerAddress);
    console.log(signerBalance);
    console.log(signerLoveBalance);
    console.log(signerApprovedLOVE);
    console.log(signerSentLOVE);
    setAccount(signerAddress);
    setBalance(signerBalance);
    setLoveBalance(signerLoveBalance);
    setApprovedLOVE(signerApprovedLOVE);
    setSentLOVE(signerSentLOVE);
  }

  async function connectWallet () {
    if (typeof window.ethereum === 'undefined') {
      alert('Please install MetaMask to use this dApp!');
      return;
    }
    setConnectBtnString("💗");
    try {
      const ethProvider = new ethers.providers.Web3Provider(window.ethereum);

      const currNetwork = await ethProvider.getNetwork();
      setIsGobi(currNetwork.chainId.toString() === "1663");

      await ethProvider.send('eth_requestAccounts', []);

      await updateBalances();

      setConnected (true);
    } catch (e) {
      console.log(e);
    }
    setConnectBtnString("Connect wallet");
    window.ethereum.on('accountsChanged', function (accounts) {
      window.location.reload();
    });
  };

  async function approveLove() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const loveContract = new ethers.Contract(LOVE_CONTRACT_ADDRESS, loveABI, signer);
    setApproveBtnString("💗");
    try {
      const tx = await loveContract.approve(SHUTTLE_CONTRACT_ADDRESS, loveBalance);
      await tx.wait();
    } catch (e) {
      console.log(e);
    }
    await updateBalances();
    setApproveBtnString("Approve LOVE");
  }

  async function xferLove() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const shuttleContract = new ethers.Contract(SHUTTLE_CONTRACT_ADDRESS, shuttleABI, signer);

    setStakeBtnString("💗");
    try {
      const tx = await shuttleContract.requestTransferToMainnet();
      await tx.wait();
    } catch (e) {
      console.log(e);
    }
    await updateBalances();
    setStakeBtnString("Transfer LOVE");
  }

  return (
    <>
      <nav>
        <ul>
          <li>
            <a href="https://faucet.lovehive.xyz/">Faucet</a>
          </li>
          <li>💗</li>
          <li>
            <a href="https://store.lovehive.xyz/">Buy</a>
          </li>
          <li>💗</li>
          <li>
            <a href="https://stake.lovehive.xyz/">Stake</a>
          </li>
          <li>💗</li>
          <li>
            <a href="https://shuttle.lovehive.xyz/">Shuttle to EON</a>
          </li>
        </ul>
      </nav>
      <div className="App">
        <h1>The LOVE shuttle to EON</h1>
        <header className="App-header">
          {isConnected === true && isGobi === true ?
            <>
              <div className="header-container"> 
                <div className="header-image">
                  <img src="/shuttle.jpg" className="App-logo" alt="logo" />
                </div>
                <div className="header-infos">
                  <p>
                    Account: {account.substring(0, 6)+'...'+account.substring(account.length-4, account.length)}<br />
                    ZEN Balance: {parseFloat(ethers.utils.formatEther(balance)).toFixed(3)}<br />
                    $LOVE Balance: {parseFloat(ethers.utils.formatEther(loveBalance)).toFixed(3)}<br />
                  </p>
                </div>
              </div>
              {
                sentLOVE.isZero() && 
                !loveBalance.isZero() && 
                approvedLOVE.lt(loveBalance) &&
                <p><button onClick={approveLove} disabled={approveBtnString === "Approve LOVE" ? "" : "disabled"}>{approveBtnString}</button></p>
              }
              {
                sentLOVE.isZero() && 
                !loveBalance.isZero() && 
                approvedLOVE.gte(loveBalance) &&
                <p><button onClick={xferLove} disabled={xferBtnString === "Transfer LOVE" ? "" : "disabled"}>{xferBtnString}</button></p>
              }
              { !sentLOVE.isZero() &&
              <p>This wallet has already sent test $LOVE to EON.<br />
              Amount : { ethers.utils.formatEther(sentLOVE) } $LOVE</p> }
              { <p>Here, you can transfer your test $LOVE to real $LOVE on EON.<br />
              Step 1 : You need to approve spending all your test $LOVE.<br />
              Step 2 : Request the transfer of your test $LOVE.<br />
              The bridging will then be made by batches.<br />
              Thank you for your patience and support</p> }
            </>
          :
            <>
              <img src="/shuttle.jpg" className="App-logo" alt="logo" />
              {!isGobi && <div className="network-error">Please switch to Gobi network</div>}
              {isGobi && !isConnected && <button className="connectButton" onClick={connectWallet}  disabled={connectBtnString === "Connect Wallet" ? "" : "disabled"}>{connectBtnString}</button>}
            </>
          }
        </header>
      </div>
      <footer>v1.0.1 - Made with 💗 on <a href="https://eon.horizen.io/docs/">HorizenEON Gobi testnet</a> by <a href="https://twitter.com/xgarreau">xgarreau</a></footer>
    </>  
  );
}

export default App;
